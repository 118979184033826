import { useState, useCallback } from "react";

import { Card, CardContent } from "@andes/card";
import Typography from "@andes/typography";
import { Password } from "@andes/textfield";
import { Button } from "@andes/button";

import { Col, Row } from "../../components/grid";

import { changePassword } from "../../pages/myAccess/controller";

import type { ResponseChangePassword } from "./types";

import type { DataFetchService } from "../../services/types";
import { useAtom } from "jotai";
import {
  type SnackbarStateProps,
  snackbarStateAtom,
} from "../../states/snackbarState";
import { sanitizeData } from "../../utils/sanitizeData";

import { get } from "../../utils/cookies";

export const ChangePassword: React.FC = () => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [helperNewPassword, setHelperNewPassword] = useState<string>("");
  const [modifierNewPassword, setModifierNewPassword] =
    useState<string>("indeterminate");
  const [helperOldPassword, setHelperOldPassword] = useState<string>("");
  const [modifierOldPassword, setModifierOldPassword] =
    useState<string>("indeterminate");

  const [snackbar, setSnackbar] =
    useAtom<SnackbarStateProps>(snackbarStateAtom);

  const validateAllPasswords = useCallback((): boolean => {
    if (!newPassword) {
      setHelperNewPassword("Necessário preencher a nova senha: ");
      setModifierNewPassword("error");
    } else {
      setHelperNewPassword("");
      setModifierNewPassword("completed");
    }

    if (!oldPassword) {
      setHelperOldPassword("Necessário preencher a senha atual: ");
      setModifierOldPassword("error");
    } else {
      setHelperOldPassword("");
      setModifierOldPassword("completed");
    }

    if (!newPassword || !oldPassword) {
      return false;
    }

    return true;
  }, [newPassword, oldPassword]);

  const handleChangePassword = useCallback(async (): Promise<void> => {
    try {
      if (!validateAllPasswords()) {
        return;
      }

      setLoadingButton(true);

      const response: ResponseChangePassword = await changePassword({
        password: oldPassword,
        new_password: newPassword,
      });

      if (response?.error) {
        const message: string | undefined = response?.exception?.message;

        if (message) {
          setSnackbar({
            ...snackbar,
            message,
            color: "red",
            show: true,
          });
        }
      }

      if (response?.success) {
        const message: string | undefined = response?.data?.message;

        if (message) {
          setSnackbar({
            ...snackbar,
            message,
            color: "green",
            show: true,
          });
        }

        setHelperNewPassword("");
        setModifierNewPassword("completed");

        setHelperOldPassword("");
        setModifierOldPassword("completed");
      }
    } catch (error: unknown) {
      const getError = error as DataFetchService;

      if (getError?.exception) {
        setSnackbar({
          ...snackbar,
          message: getError?.exception?.message,
          color: "red",
          show: true,
        });
      }
    } finally {
      setLoadingButton(false);
    }
  }, [validateAllPasswords, oldPassword, newPassword, setSnackbar, snackbar]);

  const handleOldPassword = useCallback(
    (e: Event & { target: HTMLInputElement }): void => {
      const password: string = sanitizeData(e.target.value);
      setOldPassword(password);

      if (!password) {
        return;
      }

      setHelperOldPassword("");
      setModifierOldPassword("indeterminate");
    },
    []
  );

  const handleNewPassword = useCallback(
    (e: Event & { target: HTMLInputElement }): void => {
      const password: string = sanitizeData(e.target.value);
      setNewPassword(password);

      if (!password) {
        return;
      }

      setHelperNewPassword("");
      setModifierNewPassword("indeterminate");
    },
    []
  );

  if (get("is_user_meli") === "Y") {
    return null;
  }

  return (
    <Card className="mb-4">
      <CardContent>
        <form method="post" action="/auth/user/change-pass-seller-choice-mfa">
            <Row gap={3} justify="center" className="pb-3">
            <Col size={12}>
                <Typography weight="semibold" size="l">
                Alterar Senha
                </Typography>
            </Col>
            </Row>
            <Row align="center" justify="center">
            <Col md size="12">
                <Password
                name="password"
                className="flex-fill"
                label="Senha atual"
                placeholder="Senha atual"
                helper={helperOldPassword}
                modifier={modifierOldPassword}
                showPasswordLabel="Mostrar"
                hidePasswordLabel="Ocultar"
                showHide
                onChange={handleOldPassword}
                value={oldPassword}
                />
            </Col>
            <Col md size="12">
                <Password
                name="new_password"
                className="flex-fill"
                label="Nova senha"
                placeholder="Nova senha"
                helper={helperNewPassword}
                modifier={modifierNewPassword}
                showPasswordLabel="Mostrar"
                hidePasswordLabel="Ocultar"
                showHide
                onChange={handleNewPassword}
                value={newPassword}
                />
            </Col>
            <Col size={12} md="auto" className="pt-md-2">
                <Button
                type="submit"
                className="button-quiet rounded"
                hierarchy="quiet"
                srAnnouncement="Salvar"
                fullWidth
                loading={loadingButton}
                disabled={loadingButton}
                >
                Salvar
                </Button>
            </Col>
            </Row>
        </form>
      </CardContent>
    </Card>
  );
};
