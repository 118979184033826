import { useEffect, useState } from "react";
import { useI18n } from "nordic/i18n";

import { Card, CardContent } from "@andes/card";
import Typography, {
  Title,
  Text,
  TextList,
  TextListItem,
} from "@andes/typography";

import Layout from "../../components/layout";
import { Row, Col } from "../../components/grid";

import GoodbyeAvatar from "../../assets/images/goodbye-avatar.svg";
import { get } from "../../utils/cookies";

const AboutMeliRegister = () => {
  const { i18n } = useI18n();

  const [shutdownData, setShutdownData] = useState<{
    shutdownDate: string;
  } | null>(null);

  useEffect(() => {
    if (!shutdownData) {
      setShutdownData(JSON.parse(get("shutdown_data")!));
    }
  }, [shutdownData]);

  return (
    <Layout pageTitle="Redirecionamento de cadastro">
      <Card paddingSize={32}>
        <CardContent>
          <Row gap={4} align="center" justify="center" className="mt-3">
            <Col auto className="order-1 order-md-0">
              <img
                src={GoodbyeAvatar}
                alt="Ilustração de personagem acenando"
                width={353}
                height={420}
                className="w-100"
              />
            </Col>

            <Col md>
              <Row gap={4} align="center">
                <Title component="h6" size="l" weight="semibold">
                  {i18n.gettext(
                    `Aviso de encerramento dos serviços de intermediação de envios Kangu no dia ${
                      shutdownData?.shutdownDate || ""
                    }`
                  )}
                </Title>

                <Text size="m">
                  {i18n.gettext(
                    "Queremos compartilhar a decisão de descontinuar os serviços de intermediação de envios Kangu."
                  )}
                </Text>

                <Text size="m">
                  {i18n.gettext("Já ")}

                  <Text size="m" weight="semibold">
                    {i18n.gettext(
                      "não estamos mais aceitando novos cadastros "
                    )}
                  </Text>

                  {i18n.gettext("e, ")}

                  <Text size="m" weight="semibold">
                    {i18n.gettext(
                      `a partir do dia ${
                        shutdownData?.shutdownDate || ""
                      }, não será mais possível contratar envios`
                    )}
                  </Text>

                  {i18n.gettext(" na nossa plataforma.")}
                </Text>

                <Text size="m">
                  {i18n.gettext(
                    "Confira os serviços que serão mantidos ativos:"
                  )}
                </Text>

                <TextList
                  srLabel={i18n.gettext(
                    "Lista dos serviços que serão mantidos ativos"
                  )}
                >
                  <TextListItem>
                    {i18n.gettext(
                      "Ponto parceiro/Agência Mercado Livre, que continuará recebendo pacotes de vendas do Mercado Livre. Para novos estabelecimentos, é possível realizar uma "
                    )}
                    <Typography
                      component="a"
                      color="caution"
                      target="_blank"
                      size="m"
                      weight="semibold"
                      href="https://envios.mercadolivre.com.br/agencias-mercado-livre/registro"
                    >
                      {i18n.gettext(
                        "solicitação para ser Agência Mercado Livre."
                      )}
                    </Typography>
                  </TextListItem>
                  <TextListItem>
                    {i18n.gettext(
                      "Parceria com nossa rede logística, sendo possível realizar uma "
                    )}
                    <Typography
                      component="a"
                      color="caution"
                      target="_blank"
                      size="m"
                      weight="semibold"
                      href="https://www2.kangu.com.br/cadastre-se/?mot"
                    >
                      {i18n.gettext("solicitação para ser motorista.")}
                    </Typography>
                  </TextListItem>
                </TextList>

                <Text size="m">{i18n.gettext("Um grande abraço,")}</Text>

                <Text size="m">{i18n.gettext("Time da Kangu")}</Text>
              </Row>
            </Col>
          </Row>
        </CardContent>
      </Card>
    </Layout>
  );
};

export default AboutMeliRegister;
