import { type FC, memo, useCallback, useRef, useState } from "react";

import { type SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSetAtom } from "jotai";

import { useI18n } from "nordic/i18n";

import { Card, CardContent } from "@andes/card";
import { Button, ProgressButton } from "@andes/button";
import TextField from "@andes/textfield";
import { Password } from "@andes/textfield";

import { Col, Flex, Row } from "../../components/grid";

import { Form } from "@andes/form";
import Recaptcha from "../../components/recaptcha";
import {
  loginSchema,
  LoginSchemaType,
} from "../../schemas/loginMeli/login/login";
import { loginAction } from "../../pages/loginMeli/login/controller";

import { get, remove } from "../../utils/cookies";

import { snackbarStateAtom } from "../../states/snackbarState";
import { stepsStateAtom } from "../../states/steps";

import {
  commonSchema,
  type CommonLoginSchemaType,
} from "../../schemas/login/login";

import type ReCAPTCHA from "react-google-recaptcha";

import type { DataFetchService } from "../../services/types";
import type { LoginResponseData } from "../../services/loginMeli/login/types";
import { safeRedirect } from "../../utils/url";

interface ResponseLogin {
  readonly success?: boolean;
  readonly error?: boolean;
  readonly temp_token?: string;
  readonly celular?: string;
  readonly email?: string;
  readonly opcoes_mfa?: string;
  readonly logged?: boolean;
  readonly message?: string;
}

interface CardLoginProps {
  readonly siteKey?: string;
  readonly setCurrentUser: (value: string) => void;
  readonly setResponseLogin: (value: ResponseLogin) => void;
  readonly setRedirectMeli?: (redirect: boolean) => void;
  readonly isCommonLogin?: boolean;
  readonly forgetPassword?: boolean;
}

const CardLogin: FC<CardLoginProps> = ({
  siteKey,
  setCurrentUser,
  setResponseLogin,
  setRedirectMeli,
  isCommonLogin,
  forgetPassword,
}) => {
  const { i18n } = useI18n();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timeoutButton, setTimeoutButton] = useState<number | undefined>(undefined);

  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const { formState, handleSubmit, register, setValue, clearErrors } = useForm<
    LoginSchemaType | CommonLoginSchemaType
  >({
    resolver: zodResolver(isCommonLogin ? commonSchema : loginSchema),
  });

  const setSnackbar = useSetAtom(snackbarStateAtom);

  const setStep = useSetAtom(stepsStateAtom);

  const setValuePassword = (e: { target: { value: string } }) => {
    const { value } = e.target;

    value && clearErrors("password");
    setValue("password", value);
  };

  const showSnackbarErrors = useCallback(
    (error: unknown) => {
      const getError = error as DataFetchService;
      let message = "";

      setIsLoading(false);

      if (getError?.exception) {
        message = getError?.exception?.message;
      } else if (error instanceof Error) {
        message = error.message;
      }

      setSnackbar((prev) => ({
        ...prev,
        message,
        color: "red",
        show: true,
      }));
    },
    [setSnackbar]
  );

  const handleLogged = (): void => {
    let url = "/";

    setTimeoutButton(3000);

    if (isCommonLogin) {
      const params = new URLSearchParams(document.location.search);
      url = params.get("success") ?? url;

      if (get("successRedirectUrl")) {
        url = get("successRedirectUrl", true)!;
      }
    } else if (get("utm")) {
      url = `/auth/mercadolivre/match-account?code=${get("utm")!}`;
    }

    remove("utm_resource");
    remove("utm_cs");
    safeRedirect(url);
  };

  const handleMfaEnable = (user: string, response: LoginResponseData): void => {
    setIsLoading(false);
    setCurrentUser(user);
    setResponseLogin(response);
    setStep({ current: 2 });
  };

  const handleRedirectError = (isRedirectMeli?: boolean) => {
    setIsLoading(false);
    if (isRedirectMeli && setRedirectMeli) {
      setRedirectMeli(true);
    }
  };

  const handleRedirectInactive = (): void => {
    window.location.href = "/sellers/about-meli-inativate";
  };

  const onSubmit: SubmitHandler<
    LoginSchemaType | CommonLoginSchemaType
  > = async (data) => {
    setIsLoading(true);

    const tokenRecaptcha = (await recaptchaRef.current?.executeAsync()) || "";

    await loginAction({
      data,
      handleLogged,
      handleRedirectInactive,
      handleMfaEnable,
      showSnackbarErrors,
      handleRedirectError,
      tokenRecaptcha,
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Recaptcha siteKey={siteKey} recaptchaRef={recaptchaRef} />
      <Card className="mb-4 p-2 shadow-sm">
        <CardContent>
          <Row align="center">
            <Col>
              <Row>
                <Col className="mt-4" size={12}>
                  <Flex direction="column">
                    <TextField
                      label={isCommonLogin ? "Usuário" : "E-mail"}
                      placeholder={
                        isCommonLogin
                          ? "Insira seu usuário"
                          : "Insira seu e-mail"
                      }
                      data-testid="user"
                      modifier={formState.errors.user ? "error" : undefined}
                      helper={
                        formState.errors.user?.message as string | undefined
                      }
                      {...register("user")}
                    />
                    <Password
                      label="Senha"
                      placeholder="Insira sua senha"
                      showPasswordLabel="Mostrar"
                      hidePasswordLabel="Ocultar"
                      modifier={formState.errors.password ? "error" : undefined}
                      helper={formState.errors.password?.message}
                      showHide
                      onChange={setValuePassword}
                    />
                    <Col size={12}>
                      <Row>
                        <Col size={12}>
                          <ProgressButton
                            hierarchy="loud"
                            className="rounded px-5 w-100 custom-button"
                            progressLabel={
                              isCommonLogin ? "Acessando..." : "Iniciando..."
                            }
                            type="submit"
                            loading={isLoading}
                            disabled={isLoading}
                            timeout={timeoutButton}
                          >
                            {i18n.gettext(
                              isCommonLogin ? "Acessar" : "Iniciar sessão"
                            )}
                          </ProgressButton>
                        </Col>
                        {forgetPassword && (
                          <Col size={12} className="mt-1">
                            <Button
                              hierarchy="transparent"
                              className="rounded w-100 px-0"
                              onClick={() => {
                                window.location.href = "/user/forget-pass";
                              }}
                            >
                              {i18n.gettext("Esqueci a senha")}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Flex>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardContent>
      </Card>
    </Form>
  );
};

export default memo(CardLogin);
