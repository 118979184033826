import { FC } from "react";

import Layout from "../../components/layout";
import { Container, Flex } from "../../components/grid";
import { Card, CardContent } from "@andes/card";
import { Title, Text } from "@andes/typography";

import IconDone from "../../assets/images/iconDone.svg";

import "./styles.scss";

const InativateAccount: FC = () => {
  return (
    <Layout pageTitle="Conta inativada">
      <Container>
        <Flex fullWidth justify="center" align="center">
          <Card shadow="elevated" paddingSize={24}>
            <CardContent className="done-message-container">
              <Flex
                direction="column-reverse"
                align="center"
                justify="start"
                className="flex-md-row"
              >
                <img
                  src={IconDone}
                  alt="Icon Done"
                  width={353}
                  height={420.99}
                  className="img-container"
                />
                <div>
                  <Title component="h1" size="m" className="mb-4">
                    Conta inativada pelo encerramento dos serviços Kangu
                  </Title>
                  <Text component="p" size="m" className="mb-2">
                    Queremos compartilhar a decisão de descontinuar os serviços
                    de intermediação de envios Kangu.
                  </Text>
                  <Text component="p" size="m" className="mb-2">
                    <b>
                      Sua conta já foi inativada com toda a segurança possível
                    </b>
                    , garantindo a proteção dos seus dados, depois de
                    verificarmos que não havia crédito pendente nem envios em
                    andamento.
                  </Text>
                  <Text component="p" size="m" className="mb-2">
                    Agradecemos por toda a parceria firmada ao longo desses anos
                    e pela confiança depositada em nossos serviços.
                  </Text>
                  <Text component="p" size="m" className="mb-2">
                    Um grande abraço,
                  </Text>
                  <Text component="p" size="m" className="mb-2">
                    Time da Kangu
                  </Text>
                </div>
              </Flex>
            </CardContent>
          </Card>
        </Flex>
      </Container>
    </Layout>
  );
};

export default InativateAccount;
