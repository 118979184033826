import { type FC, useEffect, useState } from "react";

import { Card } from "@andes/card";
import { Title, Text } from "@andes/typography";
import { Button } from "@andes/button";
import { useAtom } from "jotai";
import { HeaderStateProps, headerStateAtom } from "../../states/header";
import { RequestCardProps } from "./types";
import { useDcStateAtom, UseDcStateProps } from "../../states/useDc";
import { ResponseProfileChange } from "../../services/useDc/types";
import { postDataProfile } from "../../pages/useDc/controller";
import { navbarStateAtom, NavbarStateProps } from "../../states/navbar";
import { Flex } from "../../components/grid";
import IconError from "../../assets/images/error.svg";
import sendGaEvents from "../../utils/sendGaEvents";
import { CHANGE_PROFILE } from "../../constants/events";
import { get } from "../../utils/cookies";
import { AnimatePresence, motion } from "framer-motion";

const COUNTDOWN_TIME = 30;

const RequestCard: FC<RequestCardProps> = ({
  setUseSameCPF,
  setIsFormSubmitted,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [headerState, setHeaderState] =
    useAtom<HeaderStateProps>(headerStateAtom);

  const [useDcState, setUseDcState] = useAtom<UseDcStateProps>(useDcStateAtom);
  const [navbarState, setNavbarState] =
    useAtom<NavbarStateProps>(navbarStateAtom);

  const [countdown, setCountdown] = useState<number>(COUNTDOWN_TIME);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [canSwitch, setCanSwitch] = useState<boolean>(true);

  const handleSaveChangeProfile = async () => {
    try {
      sendGaEvents({
        event: CHANGE_PROFILE.MIGRACAO_MIGRAR_PARA_CPF,
        userId: get("user_id") || "",
      });

      setIsLoading(true);
      setHasError(false);
      setCanSwitch(true);

      if (useDcState.cpfCadastrado) {
        handleSameDocError();
        return;
      }

      const response: ResponseProfileChange = await postDataProfile();

      const { data } = response;

      if (data.status !== "success" || data.erros) {
        handleCountDown();
      }

      if (data.status === "success") {
        setHasError(false);
        setUseDcState({
          ...useDcState,
          documento: data.cpf,
          nome: data.nome,
          data_nasc: data.dt_nasc,
        });

        setNavbarState({
          ...navbarState,
          descricao: data.nome,
        });

        setTimeout(() => {
          setIsFormSubmitted(true);
          setUseSameCPF(false);
        }, 2000);

        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleCountDown = () => {
    setIsLoading(false);
    setHeaderState({
      ...headerState,
      state: "error",
    });
    setHasError(true);
    setCountdown(COUNTDOWN_TIME);

    let intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } else {
          clearInterval(intervalId);
          return prevCountdown;
        }
      });
    }, 1000);
  };

  const handleSameDocError = () => {
    setHeaderState({
      ...headerState,
      state: "error",
    });
    setIsLoading(false);
    setErrorMsg("O CPF informado já está cadastrado em uma outra conta Kangu.");
    setHasError(true);
    setCanSwitch(false);
  };

  const handleDenyChangeProfile = () => {
    sendGaEvents({
      event: CHANGE_PROFILE.MIGRACAO_MANTER_CNPJ,
      userId: get("user_id") || "",
    });

    window.location.href = "/seller/dashboard";
  };

  const maskCPF = (cpf: string) => {
    return cpf.toString().replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  useEffect(() => {
    setHeaderState({
      ...headerState,
      state: "default",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card className="mt-4" shadow="outline">
        <Title component="h6" color="inverted">
          Atualização disponível na sua conta!
        </Title>
      </Card>

      <Card className="mt-4 overflow-hidden">
        <Flex
          gap={3}
          direction="column"
          justify="stretch"
          fullWidth
          className="p-4"
        >
          <Title component="h6" style={{ fontSize: "20px", fontWeight: 600 }}>
            Por que alterar seu cadastro para CPF?
          </Title>
          <Flex gap={4} direction="column">
            <Text size="m">
              <span>
                A partir de 01/01/2025, todos os envios feitos por usuários CNPJ
                devem ter Nota Fiscal. Para seguir enviando com Declaração de
                Conteúdo, você deve migrar seu perfil para CPF.
              </span>
            </Text>
            <Text size="m">
              Conta CNPJ (sua conta atual)
              <ul className="mb-0">
                <li>
                  todos os envios apenas com Nota Fiscal a partir de 01/01/2025
                </li>
                <li>etiqueta com nome fantasia da sua empresa</li>
              </ul>
            </Text>
            <Text size="m">
              Conta CPF
              <ul className="mb-0">
                <li>envios com Nota Fiscal</li>
                <li>envios com Declaração de Conteúdo</li>
                <li>etiquetas apenas com nome da pessoa física</li>
              </ul>
            </Text>

            <Text size="m" color="secondary" style={{ fontSize: "14px" }}>
              Ao optar pelo transporte com Declaração de Conteúdo, o usuário
              declara que não se enquadra no conceito de contribuinte previsto
              no art. 4° da Lei Complementar n° 87/1996, ou está dispensado, por
              lei, da emissão de nota fiscal.
            </Text>
          </Flex>

          <hr className="my-1" />

          <AnimatePresence mode="wait">
            {canSwitch ? (
              <motion.div
                key="canSwitch"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                className="d-flex flex-column gap-3"
              >
                {useDcState.documento && (
                  <Text size="m" color="secondary">
                    O documento utilizado será{" "}
                    <span style={{ color: "initial" }}>
                      CPF {maskCPF(useDcState.documento)}
                    </span>
                    .
                  </Text>
                )}

                {(countdown === 0 || (countdown === 30 && !hasError)) && (
                  <Text size="m">Esta ação não poderá ser desfeita</Text>
                )}

                {hasError && (
                  <Flex gap={2} align="center">
                    <img src={IconError} alt="Icone de erro" />
                    <Text component="span" size="s" color="negative">
                      <b>
                        Erro ao tentar alterar seu cadastro, tente novamente
                        {countdown > 0 ? ` em ${countdown} seg.` : "."}
                      </b>
                    </Text>
                  </Flex>
                )}

                <Button
                  fullWidth
                  onClick={handleSaveChangeProfile}
                  disabled={isLoading || (hasError && countdown > 0)}
                >
                  Migrar para conta CPF
                </Button>

                <Button
                  hierarchy="quiet"
                  fullWidth
                  onClick={handleDenyChangeProfile}
                  disabled={isLoading || (hasError && countdown > 0)}
                >
                  Quero manter a conta CNPJ
                </Button>
              </motion.div>
            ) : (
              <motion.div
                key="cannotSwitch"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                className="d-flex flex-column gap-3"
              >
                {hasError && (
                  <Flex gap={2} align="center">
                    <img src={IconError} alt="Icone de erro" />
                    <Text
                      component="span"
                      size="s"
                      color="negative"
                      weight="semibold"
                    >
                      {errorMsg}
                    </Text>
                  </Flex>
                )}

                <Button fullWidth href="/seller/dashboard">
                  Voltar
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
        </Flex>
      </Card>
    </>
  );
};

export default RequestCard;
