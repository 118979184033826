import { type FC, memo, useState } from "react";

import { useAtomValue } from "jotai";

import { Row } from "../../components/grid";

import type { ResponseLogin } from "./types";
import { type StepsProps, stepsStateAtom } from "../../states/steps";
import FirstStep from "./firstStep";
import SecondStep from "./secondStep";
import ThirdStep from "./thirdStep";
import RedirectLoginMeli from "./redirectLoginMeli";
import ShutdownBanner from "./shutdownBanner";

export interface IStepsAuthenticate {
  readonly currentUser: string;
  readonly setCurrentUser: (value: string) => void;
}

const StepsAuthenticate: FC = () => {
  const [currentUser, setCurrentUser] = useState<string>("");
  const [redirectMeli, setRedirectMeli] = useState<boolean>(false);
  const [responseLogin, setResponseLogin] = useState<ResponseLogin | undefined>(
    undefined
  );

  const step = useAtomValue<StepsProps>(stepsStateAtom);

  return (
    <Row justify="center" className="gap-4 mt-xxl-5">
      {redirectMeli ? (
        <RedirectLoginMeli setRedirectMeli={setRedirectMeli} />
      ) : (
        <>
          <ShutdownBanner />
          {step.current === 1 && (
            <FirstStep
              setResponseLogin={setResponseLogin}
              setCurrentUser={setCurrentUser}
              currentUser={currentUser}
              setRedirectMeli={setRedirectMeli}
            />
          )}
          {step.current === 2 && (
            <SecondStep
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              responseLogin={responseLogin}
            />
          )}
          {step.current === 3 && (
            <ThirdStep
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
            />
          )}
        </>
      )}
    </Row>
  );
};

export default memo(StepsAuthenticate);
