import { Title, Text } from "@andes/typography";
import { Flex } from "../../components/grid";
import { Card } from "@andes/card";
import { type FC, useEffect, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { type HeaderStateProps, headerStateAtom } from "../../states/header";
import { Button } from "@andes/button";
import RatingStars from "../../components/ratingStars";
import { useDcStateAtom, UseDcStateProps } from "../../states/useDc";
import { postDataRating } from "../../pages/useDc/controller";
import { ResponseRating } from "../../services/useDc/types";
import sendGaEvents from "../../utils/sendGaEvents";
import { CHANGE_PROFILE } from "../../constants/events";
import { get } from "../../utils/cookies";

const SuccessCard: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const useDcState = useAtomValue<UseDcStateProps>(useDcStateAtom);

  const [userRated, setUserRated] = useState<number | boolean>(false);

  const [headerState, setHeaderState] =
    useAtom<HeaderStateProps>(headerStateAtom);

  const handleRedirect = async () => {
    try {
      if (!userRated || typeof userRated !== "number") {
        return;
      }

      setIsLoading(true);

      const response: ResponseRating = await postDataRating({
        avaliacao: userRated,
      });

      const { data } = response;

      if (data.status === "success") {
        setIsLoading(false);
      }
    } catch {
      setIsLoading(false);
    } finally {
      window.location.href = "/seller/dashboard";
      return;
    }
  };

  useEffect(() => {
    setHeaderState({
      ...headerState,
      state: "success",
    });

    sendGaEvents({
      event: CHANGE_PROFILE.MIGRACAO_CADASTRO_ATUALIZADO,
      userId: get("user_id") || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card className="mt-4" shadow="outline">
        <Title component="h6" color="inverted">
          Utilização de Declaração de conteúdo em seus fretes
        </Title>
      </Card>

      <Card className="mt-4">
        <Flex
          gap={3}
          direction="column"
          justify="stretch"
          fullWidth
          className="p-4"
        >
          <Title component="h6" weight="semibold">
            Parabéns, seu cadastro foi alterado com sucesso!
          </Title>

          <Text size="m" color="secondary">
            A partir de agora estão disponíveis todas as nossas transportadoras
            por declaração de conteúdo atreladas ao{" "}
            {useDcState.documento && (
              <Text size="m">CPF {useDcState.documento}.</Text>
            )}
          </Text>

          <hr className="my-2" />

          <Text className="text-center">
            Como você considera esse processo de alteração?
          </Text>

          <RatingStars setUserRated={setUserRated} />

          <Button fullWidth onClick={handleRedirect} disabled={isLoading}>
            {userRated
              ? "Avaliar e Ir para página inicial"
              : "Ir para página inicial"}
          </Button>
        </Flex>
      </Card>
    </>
  );
};

export default SuccessCard;
