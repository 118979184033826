import { DtoInputLogin } from "../../../containers/login/types";
import { CommonLoginSchemaType } from "../../../schemas/login/login";
import { LoginSchemaType } from "../../../schemas/loginMeli/login/login";
import LoginService from "../../../services/loginMeli/login/loginService";

import type {
  ConfirmaMfaResponse,
  ConfirmMfaRequest,
  LoginRequest,
  SendMfaRequest,
  SendMfaResponse,
} from "../../../services/loginMeli/login/types";

const dtoLogin = async (
  data: LoginSchemaType | CommonLoginSchemaType,
  tokenRecaptcha: string
): Promise<DtoInputLogin> => {
  return {
    user: data.user,
    pass: data.password,
    loginviaportal: true,
    tokenRecaptcha,
  };
};

export const loginAction = async (request: LoginRequest) => {
  try {
    const dto = await dtoLogin(request.data, request.tokenRecaptcha!);
    const response = await LoginService.login(dto as unknown as LoginRequest);

    if (!response?.data?.success || response?.data?.error) {
      if (response?.data?.redirect_to_meli) {
        request.handleRedirectError &&
          request.handleRedirectError(response?.data?.redirect_to_meli);
        return;
      }

      if (response?.data?.not_active) {
        request.handleRedirectInactive && request.handleRedirectInactive();
        return;
      }

      throw new Error(response?.data?.message);
    }

    if (response?.data?.logged) {
      request.handleLogged();
    }

    if (response?.data?.opcoes_mfa) {
      request.handleMfaEnable(dto.user, response?.data);
    }
  } catch (error) {
    request.showSnackbarErrors(error);
  }
};

export const sendMfa = async (
  data: SendMfaRequest
): Promise<SendMfaResponse> => {
  return await LoginService.sendMfa(data);
};

export const confirmMfa = async (
  data: ConfirmMfaRequest
): Promise<ConfirmaMfaResponse> => {
  return await LoginService.confirmMfa(data);
};
