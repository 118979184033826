export const MATCH_MELI = "SUCESSO_VINCULACAO_MELI";

export const ONBOARDING_STEPS = {
  shippingType: "FORMS_CPF_CNPJ",
  personType: "FORMS_ESCOLHA_PF_MEI",
  personData: {
    CNPJ: "FORMS_ESCOLHA_CNPJ",
    MEI: "FORMS_ESCOLHA_MEI",
    CPF: "FORMS_ESCOLHA_PF",
  },
  responsible: "RESPONSAVEL_CNPJ",
  summary: "CONFIRMACAO_TIPO_DOC",
  address: "CONFIRMACAO_ENDERECO",
  completed: "CADASTRO_COMPLETO",
};

export const PLATFORM_ORIGIN = {
  bagy: "BAGY",
  bling: "BLING",
  dropi: "DROPI",
  fastcommerce: "FAST_COMMERCE",
  irroba: "IRROBA",
  iset: "ISET",
  lojaintegrada: "LOJA_INTEGRADA",
  lojavirtual: "LOJA_VIRTUAL",
  nuvemshop: "NUVEMSHOP",
  opencart: "OPENCART",
  shopify: "SHOPIFY",
  tiny: "TINY",
  tray: "TRAY",
  vtex: "VTEX",
  wix: "WIX",
  woocommerce: "WOOCOMMERCE",
};

export const CHANGE_PROFILE = {
  MIGRACAO_MIGRAR_PARA_CPF: "MIGRACAO_MIGRAR_PARA_CPF",
  MIGRACAO_MANTER_CNPJ: "MIGRACAO_MANTER_CNPJ",
  MIGRACAO_ATUALIZAR_CADASTRO: "MIGRACAO_ATUALIZAR_CADASTRO",
  MIGRACAO_LEMBRE_ME_DEPOIS: "MIGRACAO_LEMBRE_ME_DEPOIS",
  MIGRACAO_NAO_TENHO_INTERESSE: "MIGRACAO_NAO_TENHO_INTERESSE",
  MIGRACAO_CADASTRO_ATUALIZADO: "MIGRACAO_CADASTRO_ATUALIZADO",
};
