import { filterXSS } from "./sanitizeData";

export const safeRedirect = (url?: string): void => {
  if (!url) {
    window.location.href = "/";
  }

  const safeUrl = filterXSS(url!, true, "/");

  window.location.href = "/mfa/safe-redirect?url=" + safeUrl;
};
